import qid from 'assets/images/qid.png';
import did from 'assets/images/did.png';
import logoYellowGrey from 'assets/images/logo-yellow-grey.png';
import logoAlfred from 'assets/images/logo_alfred.png';
import logoYellowWhite from 'assets/images/logo-yellow-white.png';
import noImage from 'assets/images/no_image.png';
import adjustable_blind from 'assets/images/devices/adjustable_blind.png';
import alfred_community_lite from 'assets/images/devices/alfred_community_lite.png';
import alfred_rent_desktop from 'assets/images/devices/alfred_rent_desktop.png';
import alfred_home_desktop from 'assets/images/devices/alfred_home_desktop.png';
import alfred_home from 'assets/images/devices/alfred_home.png';
import alfred_loft from 'assets/images/devices/alfred_loft.png';
import cut_on_off from 'assets/images/devices/cut_on_off.png';
import on_off_2_canales from 'assets/images/devices/cut_on_off.png';
import dimmable_lighting from 'assets/images/devices/dimmable_lighting.png';
import door_and_window from 'assets/images/devices/door_and_window.png';
import energy_meter from 'assets/images/devices/energy_meter.png';
import flood_sensor from 'assets/images/devices/flood_sensor.png';
import free_tension from 'assets/images/devices/free_tension.png';
import hidden_door from 'assets/images/devices/hidden_door.png';
import motion_sensor from 'assets/images/devices/motion_sensor.png';
import on_off_1_canales from 'assets/images/devices/on_off_1_canales.png';
import siren_alarm from 'assets/images/devices/siren_alarm.png';
import smart_plug from 'assets/images/devices/smart_plug.png';
import smoke_detector from 'assets/images/devices/smoke_detector.png';
import wired_motion_sensor from 'assets/images/devices/wired_motion_sensor.png';

export const IMAGES = {
  qid,
  did,
  logoYellowGrey,
  logoYellowWhite,
  noImage,
  logoAlfred,
  devices: {
    adjustable_blind,
    alfred_community_lite,
    alfred_home_desktop,
    alfred_rent_desktop,
    alfred_home,
    alfred_loft,
    cut_on_off,
    dimmable_lighting,
    door_and_window,
    energy_meter,
    flood_sensor,
    free_tension,
    hidden_door,
    motion_sensor,
    on_off_1_canales,
    on_off_2_canales,
    siren_alarm,
    smart_plug,
    smoke_detector,
    wired_motion_sensor,
  },
};
